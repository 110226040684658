export default [
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/41xNB9WGLDL._AC_SY200_.jpg",
        offer: 70,
    },
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/31Lp0DSCivS._AC_SY200_.jpg",
        offer: 70,
    },
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/31eP8rZZdwL._AC_SY200_.jpg",
        offer: 70,
    },
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/41VI1wkzEeL._AC_SY200_.jpg",
        offer: 70,
    },
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/41VI1wkzEeL._AC_SY200_.jpg",
        offer: 70,
    },
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/41xNB9WGLDL._AC_SY200_.jpg",
        offer: 70,
    },
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/41xNB9WGLDL._AC_SY200_.jpg",
        offer: 70,
    },
    {
        id: 1,
        title: "Watches Deal of the Day",
        url: "https://m.media-amazon.com/images/I/41xNB9WGLDL._AC_SY200_.jpg",
        offer: 70,
    }
]